<template>
  <DefaultLoader
    :loading="loading"
    :size="size"
    :color="color"
    :px="px"
    :py="py"
  >
    <slot v-if="show"></slot>
    <template v-else>
      <p class="text-center subtitle-1" v-html="text"></p>
    </template>
  </DefaultLoader>
</template>

<script>
const DefaultLoader = () => import("@/components/Loader/Default");

export default {
  name: "ListLoader",
  components: {
    DefaultLoader
  },
  props: {
    loading: { type: Boolean, required: false, default: false },
    size: { type: Number, required: false },
    color: { type: String, required: false },
    px: { type: Number, required: false },
    py: { type: Number, required: false },
    show: { type: Boolean, required: false, default: false },
    text: { type: String, required: false, default: "Data tidak tersedia" }
  }
};
</script>
